@import "./../node_modules/sass-autoprefixer/scss/prefixes";

/* SASS variables */
@import './sass-vars';

@import './Modal';
@import './NavBar';
@import './TheThreeRingAdvantage';
@import './ReactiveForm/index';
@import './PortfolioCaseStudies';
@import './AboutUs';
@import './PageFooter';

/*
for exotic fonts (non base system) that have a font face conversion
from https://transfonter.org/
*/
@mixin font-crossbrowser($font_name, $weight: normal, $file_prefix: "") {
  @if $file_prefix == "" {
    $file_prefix: $font_name;
  }

  /* where are the fonts stored? */
  $file_prefix: "special-fonts/" + $file_prefix;

  @font-face {
    font-family: $font_name;
    src: url($file_prefix +'.eot');
  
    src: url($file_prefix +'.eot?#iefix') format('embedded-opentype'),
      url($file_prefix +'.woff2') format('woff2'),
      url($file_prefix +'.woff') format('woff'),
      url($file_prefix +'.ttf') format('truetype'),
      url($file_prefix +'.svg#' + $file_prefix ) format('svg');
  
    font-weight: unquote($weight);
    font-style: normal;
    font-display: swap;
  }
}

/* for elements that use the Calibri font, but have normal weight */
@include font-crossbrowser("Calibri", "normal");

/* for elements that use the Calibri font, but have bold weight */
@include font-crossbrowser("Calibri", "bold", "Calibri-Bold");


body {
  font-family: Calibri,calibri,Candara,Segoe,Segoe UI,Optima,Arial,Helvetica,sans-serif; 
}

button:focus {
  outline: 0 !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

article {
  margin-top:43px;
  margin-bottom: 100px;
  margin-left: 8%;
  margin-right: auto;

  min-height: calc( 100vh - 126px );

  font-size: $thr-fontsize-base;

  img.header-img{
    max-width:40vw;
    min-width:100px;
    max-height: 40vh;
  }

  h2{
    font-size: 20pt;
    color: #333;
  }

  p, li {
    color: #666;
    max-width: 90%;
  }

  .wp-block-button__link, & .wpforms-submit{
    height: 48px;
  }

  .wp-block-button__link, & .wpforms-submit, & .wpcf7-form-control.wpcf7-submit{
    width: 302px;
    display: block;
    text-align: center;
    /*vertical-align: middle;*/
    text-decoration: none;

    font-weight: bold;
    font-size: 30px;
    padding-top: 9px;

    &:hover{
      text-decoration: underline;
      cursor: pointer;
    }

    &:disabled, &[disabled]{
      opacity: 0.5;

      &:hover{
        text-decoration: none;
        cursor: not-allowed;
      }
    }
  }

  .wp-block-button__link.red, & .wpforms-submit, & .wpcf7-form-control.wpcf7-submit{
    background-color: #9c0d0d;
    color: white;
  }

  a{
    text-decoration: none;
    color:black;
    font-weight: bold;

    &:hover{
      text-decoration: underline;
    }

    &:active, &:focus {
      outline: 0;
      border: none;
      outline-style: none;
      -moz-outline-style: none;
    }
  }

  .before-section{
    margin-top: 5em;
    margin-bottom: 2em;
  }

  .wp-block-group{
    display:table;
    width: 100%;

    .wp-block-group__inner-container{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;

      figure.wp-block-image{
        min-width: 200px;
        min-height: 96px;
        display: block;
        flex: 1;
        max-width: 250px;
        margin-inline-start: 20px;
        margin-inline-end: 20px;

        img{
          height: auto;
          margin-top: 50%;
          transform: translate(0%, -50%);
          width: 95%;
          filter: grayscale(1) brightness(0.5) contrast(2.5);
          opacity:0.5;

          &:hover{
            opacity: 1;
            filter: grayscale(0) brightness(1)
            drop-shadow(0.1px 0.1px 0.79px black)
            drop-shadow(-0.1px -0.1px 0.75px black)
            drop-shadow(0.1px 0px 0.75px black)
            drop-shadow(-0.1px 0px 0.8px black);
          }
        }
      }
    }
  }

  figure.wp-block-image{
    max-width: 90%;
    margin-top: 4em;

    img{
      height:auto;
    }
  }

  @media only screen and (max-width: $thr-max-mobile-portrait-screen) {
    figure.wp-block-image {
      margin-left: 0px;
    }
  }
}

@keyframes example {
  from {background-color: black; opacity:0;}
  to {background-color: $modal-overlay-bg; opacity: initial;}
}

@media only screen and (min-width: $thr-medium-screen) {
  article {
    margin-left: 16%;

    h2{
      font-size: 32pt;
    }
    h3{
      font-size: 24pt;
    }
    p {
      font-size: 20pt;
    }
  }
}

@media only screen and (min-width: $thr-big-screen) {
  article {
    width: 66%;

    &.home{
      width: 50%;
      margin-left: auto;
  
      h2{
        font-size: 38pt;
      }

      p {
        font-size: 25pt;
      }

      img.header-img{
        max-height: 33vh;
      }
    }
  }
}
