$transition-setting-menu: height 500ms 0ms, opacity 500ms 0ms;

@mixin animation-setting-burger-expanded {
  animation-duration: 500ms;
  animation-fill-mode: forwards;
}

button.nav_expander {
  line-height: 10px;
  padding: 0px 10px 20px 10px;
  font-weight: bold;
  font-size: 32px;
  height: 46px;
  cursor: pointer;
  background-color: transparent;
  border: none;

  position: fixed;
  right: 46px;
  top: 30px;
  z-index: 3;

  span {
    margin-top: -10px;
    display: block;

    div{
      &.one{
        transform: rotate(0deg) translate(0px, 0px);
      }
      &.two{
        transform: scaleX(1) translate(0px);
      }
      &.three{
        transform: rotate(0deg) translate(0px, 0px);
      }
    }
  }

  span.expanded {
    animation-name: span_e_kf;
    @include animation-setting-burger-expanded;

    div{
      &.one{
        animation-name: one_kf;
        @include animation-setting-burger-expanded;
      }
      &.two{
        animation-name: two_kf;
        @include animation-setting-burger-expanded;
      }
      &.three{
        animation-name: three_kf;
        @include animation-setting-burger-expanded;
      }
    }
  }
}

@keyframes span_e_kf {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(-90deg) translate(-20px, -10px); }
}

@keyframes one_kf {
  0%   { transform: rotate(0deg) translate(0px, 0px); }
  100% { transform: rotate(45deg) translate(15px, -5px); }
}

@keyframes two_kf {
  0%   { transform: scaleX(1) translate(0px); }
  100% { transform: scaleX(1) translate(-4px); }
}

@keyframes three_kf {
  0%   { transform: rotate(0deg) translate(0px, 0px); }
  100% { transform: rotate(-45deg) translate(-5px, -5px); }
}

nav#main_nav{
  background-color: #C55;
  width: 330px;
  right: 1px;
  position: fixed;
  z-index: 2;

  > ul {
    padding-top: 31px;
    padding-bottom: 31px;
    padding-left: 20px;

    transition: 1.5s;

    li:hover > a{
      opacity:0.5;
    }
  }

  li{
    list-style: none;
    padding:5px;

    a{
      font-size: 28px;
      color:white;
      text-decoration: none;
    }
  }
}

@media (min-width: $thr-old-mobile-portrait-screen) {
  nav#main_nav{
    width: 350px;
  }
}

@media (min-width: $thr-max-mobile-portrait-screen) {
  nav#main_nav{
    width: 388px;
    right: 46px;
  }
}

.show{
  opacity: 1;
  height: 364px;
  overflow: hidden;
  transition: $transition-setting-menu;
}

.hide, .hiding{
  opacity: 0;
  height: 0px;
  overflow: hidden;
  transition: $transition-setting-menu;
}

@media only screen and (min-width: $thr-medium-screen) {
  button.nav_expander, nav{
    right: 67px;
  }
}
