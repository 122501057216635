article.about-us{

  figure{
    margin-left: 0;

    max-width: 80vw;

    img{
      max-width: inherit;
    }
  }

  h3{
    margin-top: 4em;
  }
}