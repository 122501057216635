article.case-studies{
  h2:first-of-type{
    margin-bottom: 0px;
  }
  
  ul, pfhub-portfolio{
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
      list-style: none;
      font-size: 20pt;

      /*width: calc(41% - 60px);
      margin: 20px 100px 100px 0px;*/

      width: 40%;

      img{
        width:100%;
        max-height: 350px;
        object-fit: cover;
        cursor: pointer;
      }

      ul.thumbs-list{
        display:none;
      }

      li:blank, & div:blank {
        display: none;
      }
  
      div.heading-navigation{
        display: none;
      }
  
      h3{
        font-size: 32pt;
      }

      h4{
        margin-top: 25px;
        margin-bottom: 50px;
      }
  
      h6{
        font-size: 24pt;
      }

      .image-block{
        /*width: 200px;*/

        width: 100%;

        img {
          width: inherit;
          height: 335px;
          object-fit: cover;
        }

      }
  
      .description{
        ul {
          margin-bottom: 50px;
  
          li {
            list-style-type: "- ";
          }
        }
      }
  
      .button-block{
        margin-bottom: 75px;
      }
    }

    @media only screen and (max-width: $thr-big-screen) {
      li {
        width: 80%;
      }
    }

  }
}

.pfhub-portfolio-popup-wrapper{
  .clear-both{
    clear: both;
  }
}

@media only screen and (min-width: $thr-medium-screen) {
  article.case-studies > ul > li {
    width: calc(50% - 60px);
    margin: 20px 20px 100px 0px;
  }
}