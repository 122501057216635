
$modal-bg: #343434;
$modal-overlay-bg: #343434DF;

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  min-width: 320px;
  min-height: 450px;
  width: 80%;
  height: 65%;

  height: calc(95vh - 64px);
  border-radius: 4px;

  color: white;

  outline-width: 0px;

  button{
    cursor:pointer;
  }

  .modal-header{
    text-align: right;

    button{
      border: none;
      background-color: transparent;
      line-height: 43px;
      height: 43px;

      span{
        color: #EEE;
        font-weight: normal;
        font-family: auto;
        font-size:5em;

        &:hover{
          color:#AAA;
        }
      }
    }
  }

  .modal-title{
    font-size: 70px;
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .modal-body{
    text-align: center;
    font-size: 24px;
    font-weight: normal;

    .modal-logo{
      height: 7em;
      width: auto;
      filter: invert(1);
    }

    .pfhub-portfolio-popup-wrapper{
      width: 90%;

      .image-block{
        float: left;

        /*height: 500px;*/
        /*width: 55%;*/
        /*min-width:500px;*/

        img{
          width: 90%;
          height: auto;
          max-height: 66vh;
          margin-top: 1.45em;

          object-fit: cover;
        }
      }

      .right-block{
        float: left;
        width: calc(42.8% - 60px);
        /*margin-left: 60px;*/
        text-align: left;

        ul{
          &.thumbs-list{
            padding-left: 0px;

            min-width: 50vw;
          }

          li {
            list-style-type: none;
            display: inline-block;
          }
        }

        button {
          background-color: transparent;
          border: none;
        }

        img {
          width: 75px;
          height: 75px;
        }

        h3.title, & .description{
          min-width: 320px;
        }
      }
    }
    
    p {
      font-size: 36px;
      color: #FFF9;
    }

    .rec.rec-arrow{
      background-color: rgba(66, 66, 66, 0.33);
    }

    .rec.rec-pagination{
      button.rec-dot{
        height: 25px;
        width: 25px;
        box-shadow: none;
        background-color: rgba(255, 255, 255, 0.1);
      }

      button.rec.rec-dot_active{
        background-color: rgba(255, 255, 255, 0.62);
      }
    }
  }

  .modal-footer{
    text-align:center;

    button{
      width: 176px;
      height: 57px;
      background-color: #eeeeee;
      border:none;
      font-size: 30px;
      color: $modal-bg;
      font-weight: bold;

      &:hover {
        background-color: white;
        color: black;
      }
    }
  }
}

@media only screen and (max-width: $thr-medium-screen) {
  .cukhLH, .rec-slider-container {
    margin: 0px;
  }

  .Modal .modal-body .pfhub-portfolio-popup-wrapper{
    width: inherit;

    .image-block{
      height: auto;
      text-align:left;
      width: inherit;

      img{
        width: inherit;
      }
    }

    .right-block{
      margin-left: inherit;
      width: inherit;

      h3.title {
        min-width: inherit;
        font-size: 21px;
        margin-top: 11px;
        margin-bottom: 10px;
      }

      ul.thumbs-list{
        margin-top: 12px;
        margin-bottom: 12px;
      }

      .description{
        min-width: inherit;
        font-size: 18px;
      }
    }
  }

  .ReactModalPortal{
    .rec-pagination, & .bkVSTc{
      margin-top: 0px;
    }
  }
}

@media (max-width: $thr-bigger-screen) {
  .Modal .modal-body .pfhub-portfolio-popup-wrapper .image-block img{
    max-height: 40vh;
    min-width: 178px;
  }
}

@media (min-aspect-ratio: 8/6) {
  .Modal .modal-body .pfhub-portfolio-popup-wrapper .image-block{
    width: 55%;
  }

  @media (max-width: $thr-big-screen) {
    .Modal .modal-body .pfhub-portfolio-popup-wrapper .right-block{
      h3.title, & .description{
        min-width: 280px;
      }

      h3.title{
        font-size: 24px;
        margin-bottom: 12px;
      }

      ul.thumbs-list{
        margin: 1px;
      }

      .description{
        font-size: 18px;
      }
    }

    .ReactModalPortal{
      .modal-header{
        margin-top: 25px;
      }

      .rec-pagination, & .bkVSTc{
        margin-top: 1px;
      }
    }
  }
}

@media (min-width: $thr-big-screen) {
  .Modal .modal-body .pfhub-portfolio-popup-wrapper{
    .right-block{
      margin-left: 60px;
    }
  }
}

@media (min-width: $thr-bigger-screen) {
  .Modal .modal-body .pfhub-portfolio-popup-wrapper{
    .image-block{
      min-width:500px;
    }
  }
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $modal-overlay-bg;
  animation-name: example;
  animation-duration: 1s;
  z-index: 4;
}
