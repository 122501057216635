footer{
  background-color: #CCC;
  color: black;
  margin: 20pt auto 0px 0px;
  padding: 20pt 15vw 60pt 15vw;
  border-bottom: 40pt solid #888;

  h2{
    text-transform: uppercase;
    font-size: 3em;
  }

  nav{
    &, &.show{
      overflow: visible;
      padding-bottom: 25pt;
      height: initial;
    }

    ul {
      padding-left: 0px;
      column-count: 1;

      li{
        list-style: none;
        font-size: 18pt;

        margin-bottom: 20pt;
        margin-right: 30pt;

        &:first-child{
          display: none;
        }
  
        a:link, & a:visited{
          color: #333;
          text-decoration: underline #3333;
        }

        a:hover{
          color: black;
          text-decoration: underline black;
        }
      }
    }

    @media only screen and (min-width: $thr-medium-screen) {
      ul {
        column-count: 2;
      }
    }

  }

  p{
    font-size: 18pt;
  }
}
